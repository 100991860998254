/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:2fe61b3d-ac1f-453f-9efa-d3a4990499fb",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_LdjQWjKoa",
    "aws_user_pools_web_client_id": "6142u2mm7dajvgvrpbnbteefb5",
    "oauth": {},
    "aws_content_delivery_bucket": "commadminweb-20210910133049-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "https://d44rxk2smikn2.cloudfront.net",
    "aws_appsync_graphqlEndpoint": "https://2tsumrqst5dydmt2htl3bmzkcu.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;

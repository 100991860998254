





































































































import Logo from "../Logo.vue";
import { can } from "@/acl/Acl";

// can(currentUser, 'create', 'article')
import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({
  components: {
    Logo,
  },
})
export default class Navbar extends Vue {
  @Getter("auth/dbUser") dbUser!: any;
  fixed = false;
  drawer = false;
  mobile = window.innerWidth > 768 ? false : true;

  mounted() {
    window.addEventListener("resize", () => {
      const sliderWidth = window.innerWidth;
      this.mobile = sliderWidth > 768 ? false : true;
    });
  }
  get items() {
    return [
      {
        icon: "mdi-chart-pie",
        title: "ダッシュボード",
        to: "/",
        enable: can(this.currentRole, "menu", "dashboard"),
      },
      {
        icon: "mdi-account",
        title: "アカウント",
        to: "/account",
        enable: can(this.currentRole, "menu", "account"),
      },
      {
        icon: "mdi-office-building-outline",
        title: "企業",
        to: "/company",
        enable: can(this.currentRole, "menu", "company"),
      },
      {
        icon: "mdi-store",
        title: "店舗",
        to: "/store",
        enable: can(this.currentRole, "menu", "shop"),
      },
      {
        icon: "mdi-account-group",
        title: "グループ",
        enable: can(this.currentRole, "menu", "group"),
        active: false,
        childPages: [
          {
            title: "ユーザー",
            to: "/group/user",
          },
          {
            title: "ターミナル",
            to: "/group/device",
          },
        ],
      },
      {
        icon: "mdi-cellphone-cog",
        title: "端末",
        active: false,
        enable: can(this.currentRole, "menu", "terminal"),
        childPages: [
          {
            title: "チャージ機",
            to: "/terminal/charge",
          },
          {
            title: "券売機",
            to: "/terminal/ticketVending",
          },
        ],
      },
      {
        icon: "mdi-cog-outline",
        title: "管理",
        active: false,
        enable: can(this.currentRole, "menu", "management"),
        childPages: [
          {
            title: "導入実績",
            to: "/management/adopt",
          },
          {
            title: "システム操作履歴",
            to: "/logs/operation",
          },
          {
            title: "使用制限",
            to: "/management/functionRestriction",
          },
        ],
      },
    ];
  }

  get currentUserEmail() {
    console.log(this.$store.getters);
    return this.$store.getters["auth/email"];
  }

  get currentCompanyName() {
    const companies = this.$store.getters["company/companies"];
    const companyId = this.$store.getters["auth/companyId"];
    const companyName = companies.find(
      (company: any) => company.Id === companyId
    );
    return companyName ? companyName.DisplayName : " ";
  }

  get currentRole() {
    console.log("this.$store.getters");
    console.log(this.$store.getters["auth/userGroup"]);
    
    return this.$store.getters["auth/userGroup"];
  }

  companyName = "";
  isInRestriction = true;
  isSystemAdmin = false;

  @Watch("dbUser", { immediate: true, deep: true })
  async onUserChange() {
    let mainteSchedules = [];
    await this.$store
      .dispatch(
        "systemPlannedMaintenanceSchedules/getSystemPlannedMaintenanceSchedules",
        null
      )
      .then(() => {
        mainteSchedules =
          this.$store.getters[
            "systemPlannedMaintenanceSchedules/systemPlannedMaintenanceSchedules"
          ];
        if (mainteSchedules[0]?.EndTimestampMs) {
          if (
            mainteSchedules[0]?.StartTimestampMs < Date.now() &&
            mainteSchedules[0]?.EndTimestampMs > Date.now()
          ) {
            this.isInRestriction = true;
          } else {
            this.isInRestriction = false;
          }
        } else {
          this.isInRestriction = false;
        }
      });
    this.isSystemAdmin =
      (await this.$store.getters["auth/dbUser"]["CognitoGroup"]) ==
      "SystemAdmin"
        ? true
        : false;
  }

  async created() {
    let mainteSchedules = [];
    await this.$store
      .dispatch(
        "systemPlannedMaintenanceSchedules/getSystemPlannedMaintenanceSchedules",
        null
      )
      .then(() => {
        mainteSchedules =
          this.$store.getters[
            "systemPlannedMaintenanceSchedules/systemPlannedMaintenanceSchedules"
          ];
        if (mainteSchedules[0]?.EndTimestampMs) {
          if (
            mainteSchedules[0]?.StartTimestampMs < Date.now() &&
            mainteSchedules[0]?.EndTimestampMs > Date.now()
          ) {
            this.isInRestriction = true;
          } else {
            this.isInRestriction = false;
          }
        } else {
          this.isInRestriction = false;
        }
      });
    this.isSystemAdmin =
      (await this.$store.getters["auth/dbUser"]["CognitoGroup"]) ==
      "SystemAdmin"
        ? true
        : false;
  }

  logout() {
    this.$store.dispatch("auth/logout");
    this.$router.push({ path: "/login" });
  }
}
